var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0010221" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.popupParam.seniorStatusId,
                                  expression:
                                    "editable && popupParam.seniorStatusId",
                                },
                              ],
                              attrs: { label: "LBLREMOVE", icon: "remove" },
                              on: { btnClicked: _vm.deleteData },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editable,
                                  expression: "editable",
                                },
                              ],
                              attrs: {
                                url: _vm.saveUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.data,
                                mappingType: _vm.mappingType,
                                label: "LBLSAVE",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveData,
                                btnCallback: _vm.saveCallback,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              readonly: true,
                              afterIcon: _vm.editable
                                ? [
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "searchSenior",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "removeSenior",
                                    },
                                  ]
                                : null,
                              label: "LBL0010222",
                              name: "seniorName",
                            },
                            on: {
                              searchSenior: _vm.searchSenior,
                              removeSenior: _vm.removeSenior,
                            },
                            model: {
                              value: _vm.data.seniorName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "seniorName", $$v)
                              },
                              expression: "data.seniorName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: "",
                              type: "dept_user",
                              editable: _vm.editable,
                              data: _vm.data,
                              deptValue: "seniorDeptCd",
                              label: "LBL0010223",
                              name: "seniorUserId",
                            },
                            model: {
                              value: _vm.data.seniorUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "seniorUserId", $$v)
                              },
                              expression: "data.seniorUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: "",
                              label: "LBL0010217",
                              editable: _vm.editable,
                              name: "seniorDate",
                            },
                            model: {
                              value: _vm.data.seniorDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "seniorDate", $$v)
                              },
                              expression: "data.seniorDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5",
                              },
                              [
                                _c("c-radio", {
                                  attrs: {
                                    editable: _vm.editable,
                                    comboItems: _vm.dismissalFlagItems,
                                    label: "LBL0010224",
                                    name: "dismissalFlag",
                                  },
                                  on: { datachange: _vm.datachange },
                                  model: {
                                    value: _vm.data.dismissalFlag,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "dismissalFlag", $$v)
                                    },
                                    expression: "data.dismissalFlag",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7",
                              },
                              [
                                _c("c-datepicker", {
                                  attrs: {
                                    editable: _vm.editable,
                                    disabled: _vm.data.dismissalFlag !== "Y",
                                    label: "LBL0010219",
                                    start: _vm.data.seniorDate,
                                    name: "dismissalDate",
                                  },
                                  model: {
                                    value: _vm.data.dismissalDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "dismissalDate", $$v)
                                    },
                                    expression: "data.dismissalDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0010225",
                              name: "relatedCertificate",
                            },
                            model: {
                              value: _vm.data.relatedCertificate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "relatedCertificate", $$v)
                              },
                              expression: "data.relatedCertificate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0010226",
                              name: "certificateNo",
                            },
                            model: {
                              value: _vm.data.certificateNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "certificateNo", $$v)
                              },
                              expression: "data.certificateNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0010227",
                              name: "certificateAcquiDate",
                            },
                            model: {
                              value: _vm.data.certificateAcquiDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "certificateAcquiDate", $$v)
                              },
                              expression: "data.certificateAcquiDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-multi-select", {
                            attrs: {
                              editable: _vm.editable,
                              isArray: false,
                              codeGroupCd: "RELATED_LAWS_CD",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "LBL0010215",
                              disabled: true,
                              name: "relatedLaws",
                              maxValues: "3",
                            },
                            model: {
                              value: _vm.data.relatedLaws,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "relatedLaws", $$v)
                              },
                              expression: "data.relatedLaws",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              name: "qualifiyRequire",
                              label: "세부법규",
                              disabled: true,
                              rows: 2,
                            },
                            model: {
                              value: _vm.data.qualifiyRequire,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "qualifiyRequire", $$v)
                              },
                              expression: "data.qualifiyRequire",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              name: "relatedReason",
                              label: "LBL0010229",
                              rows: 2,
                            },
                            model: {
                              value: _vm.data.relatedReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "relatedReason", $$v)
                              },
                              expression: "data.relatedReason",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              name: "seniorReason",
                              label: "LBL0010230",
                              rows: 2,
                            },
                            model: {
                              value: _vm.data.seniorReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "seniorReason", $$v)
                              },
                              expression: "data.seniorReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("c-table", {
                  ref: "table",
                  attrs: {
                    title: "LBL0010231",
                    columns: _vm.grid.columns,
                    data: _vm.data.eduStatusList,
                    gridHeight: "340px",
                    filtering: false,
                    isExcelDown: false,
                    checkClickFlag: false,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-sm-6 col-md-6 col-lg-6",
                staticStyle: {
                  height: "280px",
                  "padding-top": "30px !important",
                },
              },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable,
                    label: "LBL0010232",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }